import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Accordion from "../components/Accordion"
import AirportPlan from "../images/static-only/visit-us/airport-plan.jpg"
import FindUs from "../images/static-only/visit-us/find-us.jpg"
import HeaderImage from "../images/backgrounds/mjn-stand.png"

/* Accordion Listing structure = Hero > Accordions */

class AccordionListing extends React.Component {
  componentDidMount() {
    // Inactivity()
    Accordion() 
    document.body.classList.remove('-nav-active')
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Meet MJN - Visit Us</title>
        </Helmet>
        <main role="main" className="content-main -content-accordion-listing">

          <section className="hero-area -general -accordion-listing">
            <img src={HeaderImage} alt="" className="mjn-stand" />

            <div className="hero-area__inner">
              <h1>Visit <strong>Us</strong></h1>
              <nav className="breadcrumb" role="navigation">
                  <Link to="/home" title="Home">
                    <i className="fas fa-arrow-left"></i>
                    Home
                  </Link>
              </nav>
            </div>
          </section>

          <section className="accordion-listing">
            <nav className="breadcrumb -accordion -white" role="navigation">
              <Link to="/home" title="Home">
                <i className="fas fa-arrow-left"></i>
                Home
              </Link>
            </nav>

            <div className="accordion">
              <div className="accordion__heading menu-button">Concept Store</div>
              <div className="accordion__content">
                <img src={HeaderImage} alt="" className="mjn-stand" />
                <div className="rich-text">
                  <p>This concept store at Schiphol Airport is the first of its kind for Mead Johnson Nutrition where you can discover a never-seen-before immersive, educational experience for parents and babies.</p>
                  <p>There will be access to a global selection of products available for home delivery<sup>1</sup> where you will also be able to receive exclusive airport discounts<sup>2</sup> and personalized gifts<sup>3</sup> on site by participating in store activities.</p>
                  <br />
                  <p className="small">1 Product availability varies by delivery country.</p>
                  <p className="small">2 On select products only, applied at checkout. Excludes infant formula and medical food in most countries.</p>
                  <p className="small">3 No purchase necessary. Limited quantity. Subject to stock availability and conditions. Ask staff for details.</p>
                </div>
              </div>
            </div>
            
            <div className="accordion">
              <div className="accordion__heading menu-button">Find Us</div>
              <div className="accordion__content">
                <img src={FindUs} alt="A family walking in an airport terminal" />                
                <div className="rich-text">
                  <p><strong>We are located in Lounge 3, 1st Level Schiphol Amsterdam Airport.</strong></p>
                  <img src={AirportPlan} alt="Plan of the airport" />
                </div>
              </div>
            </div>

            <div className="accordion">
              <div className="accordion__heading menu-button">Kid Friendly Airport Guide</div>
              <div className="accordion__content">
                <img src={FindUs} alt="A family walking in an airport terminal" />
                <div className="rich-text">
                  <h3>NEMO Science Museum</h3>
                  <p>NEMO Science Museum is one of the best places in Amsterdam to visit with your family. At Schiphol, you can get a taster of what's to come with 8 interactive setups. At each setup, you can learn more about technology and science by performing your own experiments. You can make sound waves visible, tame electricity and blend your face with someone else's. There are interactive test setups and experiments for young and old, as well as a playground for toddlers.</p>
                  <p>
                    <strong>Location:</strong> (After Security) NEMO Holland Boulevard<br />
                    <strong>Opening hours:</strong> 24/7
                  </p>

                  <h3>Life-Sized Cuddly Toys</h3>
                  <p>Play and cuddle with some cute and fluffy toys next to NEMO!</p>
                  <p>
                    <strong>Location:</strong> (After Security) Next to NEMO Holland Boulevard<br />
                    <strong>Opening hours:</strong> 24/7
                  </p>

                  <h3>Explore Toys</h3>
                  <p>Toys help to improve creativity of children. Here you can explore fun toys from Lego, Miffy, Barbie and her colourful wardrobe.</p>
                  <p>
                    <strong>Location:</strong> (After security) Let's Play Holland Boulevard<br />
                    <strong>Opening hours:</strong> 5:00 - 1:30
                  </p>
                  <p>
                    <strong>Location:</strong> (After security) Let's Play Lounge 2<br />
                    <strong>Opening hours:</strong> 5:00 - 1:30
                  </p>

                  <h3>LEGO<sup>&reg;</sup> Pop-Up Store</h3>
                  <p>Come back to your childhood at the LEGO<sup>&reg;</sup> pop-up store!</p>
                  <p>
                    <strong>Location:</strong> (After security) LEGO<sup>&reg;</sup> pop-up store E-pier<br />
                    <strong>Opening hours:</strong> 5:00 - 1:30
                  </p>
                  <p>
                    <strong>Location:</strong> (Before security) LEGO<sup>&reg;</sup> pop-up store Plaza<br />
                    <strong>Opening hours:</strong> 7:00 - 22:00
                  </p>

                </div>
              </div>
            </div>

          </section>

        </main>
      </Layout>
    )
  }
}

export default AccordionListing